import { isNotNil } from "es-toolkit"

export const format = (value, decimals) => {
  if (hasValue(value)) {
    return value.toFixed(decimals)
  }

  return ""
}

export const hasValue = (value) => {
  return isNotNil(value) && !isNaN(value) && value !== ""
}

export const defaultValue = (value, defaultValue) => {
  if (hasValue(value)) {
    return defaultValue
  }

  return value
}

export const SRM2RGB = [
  "#FFE699", // index 0 should not be used!
  "#FFE699",
  "#FED97A",
  "#FCCC5A",
  "#FBBF3B",
  "#F9B11B",
  "#F7A500",
  "#F09A00",
  "#EA8F00",
  "#E38500",
  "#DC7A00",
  "#D57000",
  "#CE6800",
  "#C76000",
  "#C05900",
  "#B95100",
  "#B34A00",
  "#AC4300",
  "#A53D00",
  "#9F3700",
  "#983000",
  "#922B00",
  "#8C2700",
  "#862300",
  "#7F1F00",
  "#791B00",
  "#731700",
  "#6D1401",
  "#671101",
  "#610E01",
  "#5B0B02",
  "#570903",
  "#520904",
  "#4E0805",
  "#490706",
  "#450607",
  "#410608",
  "#3F0708",
  "#3C0709",
  "#390809",
  "#36080A",
]
